import getClientSideQueryClient from './queryClient'
import { useDeleteContactMutation } from './mutations/deleteContact'
import { useContactDeleteMutation } from './mutations/contactDelete'
import { useContactCreateMutation } from './mutations/contactCreate'
import { useContactUpdateMutation } from './mutations/contactUpdate'
import { useBankingDetailsMutation } from './mutations/updateBankingDetails'
import { useUpdateTaxInformationMutation } from './mutations/updateTaxInformation'

export {
  getClientSideQueryClient,
  useDeleteContactMutation,
  useContactCreateMutation,
  useContactDeleteMutation,
  useBankingDetailsMutation,
  useContactUpdateMutation,
  useUpdateTaxInformationMutation,
}
